import React, {useRef} from "react";
import "./contact.css";
import {MdOutlineEmail} from 'react-icons/md'
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_auqvib5', 'template_li3gqc6', form.current, 'VkT9AQ8QTt44tka0k')
      .then((result) => {
          console.log(result.text);
          alert("Message Sent!")
          clearForm()
      }, (error) => {
          console.log(error.text);
          alert("Message failed")
          clearForm()
      });
  };

  const clearForm = () => {
    var name = document.getElementById("name")
    var email = document.getElementById("email")
    var message = document.getElementById("message")

    name.value = '';
    email.value = '';
    message.value = '';
  }

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>Ncolstad@gmail.com</h5>
            <a href="mailto:ncolstad@gmail.com" target="_blank" rel="noopener noreferrer">Send an Email</a>
          </article>

          {/* <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>Ncolstad@gmail.com</h5>
            <a href="mailto:ncolstad@gmail.com">Send a Message</a>
          </article> */}
        </div>
        {/* END OF CONTACT OPTONS  */}

        <form ref={form} onSubmit={sendEmail}>
          <input id="name" type="text" name="name" placeholder="Your Full Name" required/>
          <input id="email" type="email" name="email" placeholder="Your Email" required/>
          <textarea id="message" name="message" rows="7" placeholder="Your Message" required></textarea>
          <button id="button" type="submit" className="btn btn-primary">Send Message</button>
          
          {/* <button className="btn" id="success">Your Message Was Sent!
          </button>
          <button className="btn" id="danger">There was an error.
          </button> */}
        </form>
        
      </div>
    </section>
  )
};

export default Contact;
