import React from "react";
import "./portfolio.css";
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Full-Stack Banking App",
    github: "https://github.com/nickcolstad/banking-app.git",
    demo: "https://nicholas-colstadbankingapplication.netlify.app/"
  },
  {
    id: 2,
    image: IMG2,
    title: "Restaurant Ordering App",
    github: "https://github.com/nickcolstad/banking-app.git",
    demo: "https://youtu.be/Ik3-ShRM5To"
  },
  {
    id: 3,
    image: IMG3,
    title: "Live Bus Locator",
    github: "https://github.com/nickcolstad/Bus-Tracker",
    demo: "https://youtu.be/EZK4peQrwS4"
  }
]

const Portfolio = () => {
  return ( 
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt="title"></img>
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github} className="btn" target="_blank" rel="noopener noreferrer">Github</a>
                  <a href={demo} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
};

export default Portfolio;
