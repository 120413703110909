import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/me.png";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  return (
    <header id="home">
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Nick Colstad</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        <CTA />
        <HeaderSocials />

        <img className="me" src={ME} alt="me" />

        <div className="scroll__graphic">
          <svg className="scroll stagger1" width="27" height="47" viewBox="0 0 54 94">
            <g id="scroll" transform="translate(-196 -711)">
              <g id="Group_20" data-name="Group 20">
                <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(196 711)" fill="none" stroke="#d3cb33" stroke-width="6">
                  <rect width="54" height="94" rx="27" stroke="none" />
                  <rect x="3" y="3" width="48" height="88" rx="24" fill="none" />
                </g>
                <circle className="circle" id="Ellipse_1" data-name="Ellipse 1" cx="16.5" cy="16.5" r="16.5" transform="translate(207 722)" fill="#fff" />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </header>
  );
};

export default Header;
