import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://github.com/nickcolstad" target="_blank" rel="noopener noreferrer">
        <BsLinkedin />
      </a>
      <a href="linkedin.com/in/nick-colstad-5373b5148" target="_blank" rel="noopener noreferrer">
        <BsGithub />
      </a>
    </div>
  );
};

export default HeaderSocials;
