import React from "react";
import { useState } from "react";
import "./nav.css";

import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BsBook } from "react-icons/bs";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageAltEdit } from "react-icons/bi";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#home");

  return (
    <nav>
      <a href="#home" title="Home" onClick={() => setActiveNav("#home")} className={activeNav === "#home" ? "active" : ""}>
        <AiOutlineHome />
      </a>
      <a href="#about" title="About" onClick={() => setActiveNav("#about")} className={activeNav === "#about" ? "active" : ""}>
        <AiOutlineUser />
      </a>
      <a href="#experience" title="Experience" onClick={() => setActiveNav("#experience")} className={activeNav === "#experience" ? "active" : ""}>
        <BsBook />
      </a>
      <a href="#services" title="Services" onClick={() => setActiveNav("#services")} className={activeNav === "#services" ? "active" : ""}>
        <RiServiceLine />
      </a>
      <a href="#contact" title="Contact" onClick={() => setActiveNav("#contact")} className={activeNav === "#contact" ? "active" : ""}>
        <BiMessageAltEdit />
      </a>
    </nav>
  );
};

export default Nav;
