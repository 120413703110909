import React from "react";
import "./about.css";
import ME from "../../assets/me-about.jpeg";
import { FaAward } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know Nick</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Me"></img>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>1+ years of coding</small>
            </article>

            <article className="about__card">
              <CgProfile className="about__icon" />
              <h5>Bootcamp Graduate</h5>
              <small>MIT Fullstack Certificate</small>
            </article>

            <article className="about__card">
              <AiOutlineFundProjectionScreen className="about__icon" />
              <h5>Projects</h5>
              <small>3 Portfolio Projects</small>
            </article>
          </div>
          <p id="personal">Hey! Welcome to my personal portfolio website! I am a recent graduate of the MIT Full-Stack Coding Bootcamp covering the JavaScript MERN stack. At somepoint this year, I plan to work as a full-time software devleoper so I can grow as an individual and also be part of a team of developers working together. The past year for me has been all about acquiring the necessary skils to develop software, and now I am ready to work and apply what I have learned. Feel free to contact me by viewing the bottom of the page. Thanks for checking out my webiste!</p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
