import React from "react";
import "./services.css";
import { AiOutlineCheck } from "react-icons/ai";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Can Do</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Create applications built for the web browser.</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Leverage the JavaScript MERN stack to join the front-end and back-end.</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Effectively write SQL to manage and view data.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>UI / UX Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Design intuitive user interfaces.</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Manage client-side validation.</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Turn ideas into real products through software.</p>
            </li>
          </ul>
        </article>
  
        <article className="service">
          <div className="service__head">
            <h3>Soft Skills</h3>
          </div>

          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Strong in verbal and written communication among coworkers and clients.</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Actively listens in order to ask the right questions to solve problems.</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>A successful team player working for the goal of the group.</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
